<template>
  <div>
    <b-row class="mb-5" align-v="center">
      <b-col>
        <h1 class="h4 mt-0">Planos Municipais</h1>
        <p class="text-body1 text-gray-400 mt-0">
          A ferramenta de gestão de Planos Municipais, visa apresentar aos
          tomadores de decisão, uma visão estratégica da situação das Políticas
          Públicas, construída a partir da análise das metas e indicadores
          estabelecidos nos planos setoriais do município.
        </p>
      </b-col>
      <b-col sm="auto">
        <b-button
          variant="outline-light"
          class="border-0 mr-1"
          @click="$bvModal.show('plan_draggable' + item)"
        >
          <b-icon icon="gear" />
        </b-button>
        <b-button
          v-if="$can('strategic_plan:create')"
          type="button"
          variant="primary"
          :to="{ name: 'strategic_plans_create' }"
        >
          <b-icon icon="plus" />
          <span class="label">Novo Plano</span>
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        md="6"
        lg="4"
        class="border-0 position-relative mb-4"
        v-for="(item, index) in strategic_plans"
        :key="index"
      >
        <ListItem :strategic_plan="item" class="h-100" />
      </b-col>
    </b-row>

    <div class="overflow-auto row my-5">
      <b-pagination-nav
        v-model="page"
        :link-gen="linkGen"
        :number-of-pages="pagination.last_page"
        use-router
        class="mx-auto"
      ></b-pagination-nav>
    </div>
    <ModalDraggable
      :items="strategic_plans"
      title="Ordenar planos estratégicos"
      :id="'plan_draggable' + item"
      @order="orderById($event, item)"
    />
  </div>
</template>

<script>
import ListItem from "../components/ListItem";
import { mapState, mapActions } from "vuex";
import ModalDraggable from "@/components/modal/ModalDraggable";

export default {
  components: { ListItem, ModalDraggable },

  data() {
    return {
      page: 1
    };
  },

  computed: {
    ...mapState("strategic_plans", ["strategic_plans", "pagination"]),
    item() {
      return this.strategic_plans.map(item => item.id);
    }
  },
  methods: {
    ...mapActions("strategic_plans", [
      "ActionGetAll",
      "ActionOrderStrategicPlansById"
    ]),
    delete_strategic_plan(strategic_plan) {
      return strategic_plan;
    },

    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    async orderById($event, id) {
      try {
        let payload = {
          id,
          ordered_strategic_plans: $event
        };

        await this.ActionOrderStrategicPlansById(payload);
        this.$notifications.addNotification({
          message: "Ordem alterada com sucesso",
          type: "success"
        });
        await this.ActionGetAll({ page: this.page });
      } catch (error) {
        let error_message = "Erro ao salvar";
        this.$notifications.addNotification({
          message: error_message,
          icon: "exclamation-triangle",
          type: "danger"
        });
      }
    }
  },

  async created() {
    if (this.$route.query.page > 1) {
      this.page = this.$route.query.page;
    }
    await this.ActionGetAll({ page: this.page });
  }
};
</script>

<style>
.plans-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
}

.plans-list p {
  font-size: 0.75rem;
  color: #666;
  margin-top: 2rem;
}

.plan-icon {
  font-size: 4rem;
}

.semafaro-s {
  position: absolute;
  width: 40px !important;
  border-radius: 10px !important;
  right: 10px;
  top: -15px;
}

.text-order {
  color: #000;
  font-weight: normal;
  cursor: pointer;
  display: block;
  max-width: 60px;
  margin-left: auto;
  border: 1px solid transparent;
}

.text-order:hover {
  color: #335080;
  font-weight: normal;
  cursor: pointer;
  border: 1px solid #335080;
}
</style>
