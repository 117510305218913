<template>
  <b-card
    class="plano-lista-item"
    header-class="bg-white border-0"
    footer-class="bg-white"
  >
    <template #header>
      <card-header
        :show-status="false"
        :label="strategic_plan.name"
        :icon="plan_icon"
        :caption-top="formatedCaption"
        label-class="h6 pr-5"
      />
    </template>
    <traffic-light class="semafaro-s" :status="statusPlan" light-size="24px" />
    <b-card-text class="text-muted text-body1">{{ description }}</b-card-text>
    <template #footer>
      <b-row no-gutters align-v="end" class="my-2">
        <b-col>
          <b-card-text class="text-muted text-caption2">
            Visualizar detalhes do plano
          </b-card-text>
        </b-col>
        <b-col sm="auto">
          <b-link
            class="py-2 px-3 link-button text-decoration-none text-body1"
            :to="formatedLink"
          >
            Acessar plano <b-icon icon="chevron-right" />
          </b-link>
        </b-col>
      </b-row>
    </template>
  </b-card>
</template>

<script>
import TrafficLight from "@/components/TrafficLight/TrafficLight.vue";
import CardHeader from "@/components/Home/Card/CardHeader.vue";

export default {
  props: {
    strategic_plan: {
      type: Object,
      required: true
    }
  },
  components: {
    TrafficLight,
    CardHeader
  },
  data: () => ({}),
  computed: {
    statusPlan() {
      return this.strategic_plan.config.plan_structure.status;
    },
    plan_icon() {
      if (this.strategic_plan.icon) {
        var icon = this.strategic_plan.icon.split(" ");
        if (icon.length > 1) {
          return icon;
        } else {
          return this.strategic_plan.icon;
        }
      }
      return false;
    },
    description() {
      return this.strategic_plan.description
        ? this.strategic_plan.description.substring(0, 240) + "..."
        : "";
    },
    formatedCaption() {
      const startDate = this.strategic_plan.start_term;
      const endDate = this.strategic_plan.end_term;
      const formatedStartDate = this.$options.filters.format_date(startDate);
      const formatedEndDate = this.$options.filters.format_date(endDate);
      return `De ${formatedStartDate} a ${formatedEndDate}`;
    },
    formatedLink() {
      const id = this.strategic_plan.id;
      return {
        name: "strategic_plans_show",
        params: { id }
      };
    }
  }
};
</script>

<style lang="scss">
.plano-lista-item h5,
.plano-lista-item h6 {
  max-width: 82%;
}

.link-button {
  border-radius: 6px !important;
  a {
    color: var(--secondary) !important;
  }

  &:hover {
    background: rgba(var(--primary-rgb), 0.1);
    a {
      color: var(--primary) !important;
    }
  }
}
</style>
